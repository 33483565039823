
<template>
  <div>
    <transition name="el-zoom-in-top">
      <div v-show="show" class="header">
        <div class="header_item">
          <p style="font-weight: 700; cursor: pointer" @click="toTop">
            乡村振兴
          </p>
          <p @click="casein" style="cursor: pointer">&nbsp;|&nbsp;</p>
          <p @click="survey" style="cursor: pointer"></p>
        </div>
        <div class="header_item">
          <p>400-6066-220</p>
          <!-- <el-input class="input" placeholder="请输入内容" v-model="input">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input> -->
        </div>
      </div>
    </transition>
    <div>
      <img
        v-for="(item, index) in data"
        :key="index"
        style="width: 100%; display: block"
        v-lazy="item.img"
        :id="item.notes"
      />
    </div>
    <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备17045813号-5</a>
    <div class="phone_icon">
      <el-button
        @click="open"
        type="primary"
        icon="el-icon-phone-outline"
        circle
      ></el-button>
    </div>
    <transition name="el-fade-in">
      <div class="WeChat transition-box" v-show="show && show2">
        <i class="el-icon-close close" @click="close" style="color: #999"></i>
        <div style="font-weight: 800">您好</div>
        <img src="erweima.png" alt="" style="height: 150px; width: 150px" />
        <div style="font-size: 24px; margin-top: 10px; font-weight: 600">
          微信扫一扫
        </div>
        <div style="font-size: 24px; font-weight: 600">立即联系我们</div>
        <!-- <el-button type="success" round>在线客服</el-button> -->
        <div style="font-size: 22px; margin-top: 10px; font-weight: 600">
          电话咨询
        </div>
        <div style="font-weight: 800" @click="callPhone()">
          <i class="el-icon-phone-outline"></i>400-6066-220
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return { data: [], show: true, input: "", show2: true, statistics: "" };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // 监听（绑定）滚轮 滚动事件
    window.addEventListener("beforeunload", () => {
      this.$axios({
        url: "api/before_destroy/?statistics=" + this.statistics,
        method: "GET",
      }).then((res) => {
        console.log(res);
        // this.data = res.data.data;
      });
    });
  },
  methods: {
    survey() {
      // val想要跳往的dom的id
      const dom = document.querySelector(`#dy`);
      // 调用dom的scrollIntoView方法
      if (dom) {
        dom.scrollIntoView(true);
      }
    },
    casein() {
      // val想要跳往的dom的id
      const dom = document.querySelector(`#zp`);
      // 调用dom的scrollIntoView方法
      if (dom) {
        dom.scrollIntoView(true);
      }
    },
    open() {
      this.show = true;
      this.show2 = true;
    },
    close() {
      this.show = false;
      this.show2 = false;
    },
    callPhone() {
      // 调用拨号功能
      window.location.href = "tel://400-6066-220";
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    handleScroll() {
      // 页面滚动距顶部距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      if (scroll < 0) {
        this.show = true;
      } else if (scroll > 5) {
        this.show = false;
      }
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("触底了");
        this.data = this.data.concat(this.data);
      }
    },
  },
  created() {
    this.$axios({
      url: "api/",
      method: "GET",
    }).then((res) => {
      console.log(res);
      this.data = res.data.data;
      this.statistics = res.data.statistics;
    });
  },
};
</script>

<style>
.close {
  position: absolute;
  top: 12px;
  right: 12px;
}
.phone_icon {
  position: fixed;
  bottom: 100px;
  right: 0;
}
.WeChat {
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 280px;
  /* border: 1px solid red; */
  border-radius: 12px;
  height: 500px;
  background-color: rgba(251, 251, 251, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* opacity: 0.6; */
  color: red;
  font-size: 32px;
  font-family: HOS;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.header {
  width: calc(100% - 16px);
  background-color: rgba(8, 7, 7, 0.7);
  position: fixed;
  top: 0;
  left: 8px;
  right: 8px;
  z-index: 1;
  color: #fff;
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  font-family: HOS;
}
.header_item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* width: 50%; */
}
@media screen and (min-width: 1200px) {
  .header_item {
    font-size: 48px;
  }
  .input {
    width: 300px !important;
  }
}
@media screen and (min-width: 800px) and (max-width: 1199px) {
  .header_item {
    font-size: 38px;
  }
  .input {
    width: 150px !important;
  }
}
@media screen and (max-width: 799px) and (min-width: 600px) {
  .header_item {
    font-size: 28px;
  }
  .input {
    width: 100px !important;
  }
}
@media screen and (max-width: 599px) and (min-width: 500px) {
  .header_item {
    font-size: 18px;
  }
  .input {
    width: 90px !important;
  }
}
@media screen and (max-width: 499px) {
  .header_item {
    font-size: 12px;
  }
  .input {
    width: 80px !important;
  }
}
</style>